<template>
  <div class="BGS">
    <div class="title">账号安全</div>
    <div class="from">
      <div class="from_title">修改手机号</div>
      <input type="text" class="y_ipt" :placeholder="user_information.phone" v-model="phone" disabled>
      <div class="code">
        <input type="text" class="codeipt" v-model="sendCode1">
        <button class="codebtn obtain_code" @click="sendCode" :disabled="smsFlag">{{sendTime}}</button>
      </div>
    </div>
    <div class="Submit" @click="Submit()">下一步</div>
  </div>
</template>

<script>
import Axios from "axios";
import md5 from "js-md5";
export default {
 metaInfo(){
     return{
        title: '换绑手机号',
        meta: [
            {
                name: 'keywords',
                content: '鹰眼投诉_消费投诉维权平台,集体投诉,商家投诉,权威帮帮团,最新投诉报道,打假维权,质量曝光,客服咨询'
            },
            {
                name: 'description',
                content:'鹰眼投诉_消费投诉维权平台是驱动中国旗下的投诉平台,快速解决消费投诉,投诉维权,共享服务投诉,购物平台投诉,旅游出行投诉,住宿投诉,娱乐生活投诉,教育培训投诉,金融支付投诉等,拥有海量企业库,各领域专家,专业律师团队及权威帮帮团来帮助消费者。'
            }
        ]
     }     
    },
  data() {
    return {
      user_information: {},
      sendTime: "获取验证码",
      snsMsgWait: 120,
      phone: "", //绑定手机号
      smsFlag: false,
      sendCode1: "", //验证码输入框
    };
  },
  methods: {
    Submit() {
        var that=this;
        Axios({
        method: "post",
        url: "https://weixin.qudong.com/api/user/check_code",
        params: {
          token:localStorage.getItem("token"),
          phone: that.user_information.phone,
          code:that.sendCode1 ,
        },
      }).then((res) => {
        //console.log(res);
        if(res.data.code ==200){
        that.$message.success(res.data.msg);
        setTimeout("location.href='/PersonalCenter/NewBind'",1500);
        }else{
            that.$message.success(res.data.msg);
        }
      });
    },
    // 获取验证码
    sendCode() {
      var that = this;
      let phone = that.user_information.phone;
      //console.log(phone);
      let md_phone = phone.substring(7);
      const myreg = /^[1][3-9][0-9]{9}$/;
      if (!myreg.test(phone)) {
        this.$message.error("电话号码格式不对");
        return;
      }
      Axios({
        method: "post",
        url: "https://weixin.qudong.com/api/user/security_code",
        params: {
          phone_num: phone,
          captcha: md5(md_phone),
        },
      }).then((res) => {
        //console.log(res);
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        var inter = setInterval(
          function () {
            (that.smsFlag = true),
              (that.sendTime = that.snsMsgWait + "s后重发"),
              (that.snsMsgWait = that.snsMsgWait - 1);
            if (that.snsMsgWait < 0) {
              clearInterval(inter);
              that.sendTime = "获取验证码";
              that.snsMsgWait = 120;
              that.smsFlag = false;
            }
          }.bind(this),
          1000
        );
      });
    },
  },
  mounted() {
    let user_information = localStorage.getItem("user_information");
    //console.log(JSON.parse(user_information));
    this.user_information = JSON.parse(user_information);
  },
};
</script>

<style scoped>
.BGS {
  width: 960px;
  background: #fff;
  margin-left: 20px;
}
.title {
  width: 100%;
  height: 76px;
  text-align: center;
  line-height: 76px;
  color: #fefefe;
  background: #1f72d9;
  font-size: 24px;
}
.from {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
.from_title {
  font-size: 20px;
  color: #333333;
  margin-bottom: 30px;
}
.y_ipt {
  width: 336px;
  height: 40px;
  border: 1px solid #999999;
  border-radius: 5px;
  padding-left: 15px;
}
.code {
  margin-top: 20px;
}
.codeipt {
  width: 185px;
  height: 42px;
  border: 1px solid #666666;
  border-radius: 5px;
  margin-right: 30px;
  padding-left: 15px;
}
.codebtn {
  width: 119px;
  height: 42px;
  background: #1f72d9;
  border-radius: 5px;
  border: none;
  color: #fff;
  cursor: pointer;
}
.Submit {
  width: 336px;
  height: 42px;
  background: #1f72d9;
  border-radius: 5px;
  margin: 30px auto;
  text-align: center;
  line-height: 42px;
  color: #fff;
  cursor: pointer;
}
</style>